/*
  START GLOBAL STYLES
*/

.full-width {
  width: 100%;
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}

.horizontal-gutter {
  padding-right: var(--horizontal-gutter);
  padding-left: var(--horizontal-gutter);
}

.horizontal-gutter-margin {
  margin-right: var(--horizontal-gutter);
  margin-left: var(--horizontal-gutter);
}

ion-item {
  --background: var(--ion-background-color);
}

.child-items-transparent-bg ion-item {
  --background: transparent;
}

.child-items-light-lines ion-item::part(native) {
  border-color: white;
}

.basic-form ion-item {
  --background: transparent;
}

.basic-form ion-item::part(native) {
  border-color: white;
  padding-left: 0;
}

.basic-form ion-input {
  --padding-bottom: 0;
  --placeholder-color: white;
  --placeholder-opacity: 0.6;
  color: white;
  font-family: var(--heading-font);
}

.basic-form ion-input::part(native):-webkit-autofill {
  color: white;
}

.basic-form.dark ion-item {
  --background: transparent;
}

.basic-form.dark ion-item::part(native) {
  border-color: black;
  padding-left: 0;
}

.basic-form.dark ion-input {
  --padding-bottom: 0;
  --placeholder-color: black;
  --placeholder-opacity: 0.6;
  color: black;
  font-family: var(--heading-font);
}

.basic-form.dark ion-input::part(native):-webkit-autofill {
  color: black;
}

.bubble-form ion-item {
  --background: transparent;
}

.bubble-form ion-input::part(native):-webkit-autofill {
  color: black;
}

.bubble-form ion-item::part(native) {
  padding-left: 0;
}

.bubble-form ion-item {
  --inner-padding-end: 0;
}

ion-item::part(detail-icon) {
  font-size: 50px;
}

ion-item > ion-label {
  padding-bottom: 8px;
}

ion-range {
  --pin-background: var(--ion-background-color-tint);
}

ion-range::part(bar-active) {
  background: var(--ion-color-accent-two);
  height: 4px;
}

ion-range::part(bar) {
  background: var(--ion-color-light);
  height: 2px;
}

ion-range::part(pin) {
  color: var(--ion-color-text);
  font-weight: bold;
}

ion-range::part(knob) {
  background: var(--ion-background-color-tint);
}

ion-range.range-has-pin {
  padding-top: 20px;
}

ion-segment {
  --background: var(--ion-color-main);
}

ion-segment-button {
  --color: var(--ion-color-constant-light);
  --color-checked: var(--ion-color-text);
}

.color-text-transition {
  transition: color 0.5s;
}

.zip-popover {
  z-index: 3;
}

ion-item::part(detail-icon) {
  transition: all 0.3s;
}

ion-item.active-section::part(detail-icon) {
  transform: rotate(90deg);
}

ion-toast.standard-toast::part(header) {
  font-size: 18px;
  padding-bottom: 5px;
}

strong {
  font-weight: Bold;
}

ion-avatar.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaderboard-dashboard-item::part(detail-icon) {
  stroke: var(--main);
  opacity: 1;
}

ion-toolbar {
  color: var(--ion-color-main-text);
}

ion-content.content-width {
  max-width: 500px;
  width: 100%;
}

ion-content.basic-bg {
  --background: url(./images/basic-bg.jpg) no-repeat center center / cover;
}

ion-content.basic-bg.basic-bg-pad {
  /* --padding-top: 130px; */
}

.useHeadingFont {
  font-family: var(--heading-font) !important;
}

/*
	END GLOBAL STYLES
*/

/*
	COLOR SHORTCUTS
*/

.ion-color-basic-grey {
  --ion-color-base: var(--ion-color-basic-grey);
}

.ion-color-light-grey {
  --ion-color-base: var(--ion-color-light-grey);
}

.ion-color-constant-light {
  --ion-color-base: var(--ion-color-constant-light);
  --ion-color-base-rgb: var(--ion-color-constant-light-rgb);
  --ion-color-contrast: var(--ion-color-constant-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-constant-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-constant-light-shade);
  --ion-color-tint: var(--ion-color-constant-light-tint);
}

.ion-color-constant-dark {
  --ion-color-base: var(--ion-color-constant-dark);
  --ion-color-base-rgb: var(--ion-color-constant-dark-rgb);
  --ion-color-contrast: var(--ion-color-constant-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-constant-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-constant-dark-shade);
  --ion-color-tint: var(--ion-color-constant-dark-tint);
}

.ion-color-main {
  --ion-color-base: var(--ion-color-main);
  --ion-color-base-rgb: var(--ion-color-main-rgb);
  --ion-color-contrast: var(--ion-color-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-shade);
  --ion-color-tint: var(--ion-color-main-tint);
}

.ion-color-text {
  --ion-color-base: var(--ion-color-text);
  --ion-color-base-rgb: var(--ion-color-text-rgb);
  --ion-color-contrast: var(--ion-color-text-contrast);
  --ion-color-contrast-rgb: var(--ion-color-text-contrast-rgb);
  --ion-color-shade: var(--ion-color-text-shade);
  --ion-color-tint: var(--ion-color-text-tint);
}

.ion-color-main-text {
  --ion-color-base: var(--ion-color-main-text);
  --ion-color-base-rgb: var(--ion-color-main-text-rgb);
  --ion-color-contrast: var(--ion-color-main-text-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-text-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-text-shade);
  --ion-color-tint: var(--ion-color-main-text-tint);
}

.ion-color-primary-bg {
  --ion-color-base: var(--ion-color-primary-bg);
  --ion-color-base-rgb: var(--ion-color-primary-bg-rgb);
  --ion-color-contrast: var(--ion-color-primary-bg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-bg-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-bg-shade);
  --ion-color-tint: var(--ion-color-primary-bg-tint);
}

.ion-color-secondary-bg {
  --ion-color-base: var(--ion-color-secondary-bg);
  --ion-color-base-rgb: var(--ion-color-secondary-bg-rgb);
  --ion-color-contrast: var(--ion-color-secondary-bg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary-bg-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary-bg-shade);
  --ion-color-tint: var(--ion-color-secondary-bg-tint);
}

.ion-color-accent-one {
  --ion-color-base: var(--ion-color-accent-one);
  --ion-color-base-rgb: var(--ion-color-accent-one-rgb);
  --ion-color-contrast: var(--ion-color-accent-one-contrast);
  --ion-color-contrast-rgb: var(--ion-color-accent-one-contrast-rgb);
  --ion-color-shade: var(--ion-color-accent-one-shade);
  --ion-color-tint: var(--ion-color-accent-one-tint);
}

.ion-color-accent-two {
  --ion-color-base: var(--ion-color-accent-two);
  --ion-color-base-rgb: var(--ion-color-accent-two-rgb);
  --ion-color-contrast: var(--ion-color-accent-two-contrast);
  --ion-color-contrast-rgb: var(--ion-color-accent-two-contrast-rgb);
  --ion-color-shade: var(--ion-color-accent-two-shade);
  --ion-color-tint: var(--ion-color-accent-two-tint);
}

.ion-color-accent-three {
  --ion-color-base: var(--ion-color-accent-three);
  --ion-color-base-rgb: var(--ion-color-accent-three-rgb);
  --ion-color-contrast: var(--ion-color-accent-three-contrast);
  --ion-color-contrast-rgb: var(--ion-color-accent-three-contrast-rgb);
  --ion-color-shade: var(--ion-color-accent-three-shade);
  --ion-color-tint: var(--ion-color-accent-three-tint);
}

.ion-color-dashboard-accent-one {
  --ion-color-base: var(--ion-color-dashboard-accent-one);
  --ion-color-base-rgb: var(--ion-color-dashboard-accent-one-rgb);
  --ion-color-contrast: var(--ion-color-dashboard-accent-one-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dashboard-accent-one-contrast-rgb);
  --ion-color-shade: var(--ion-color-dashboard-accent-one-shade);
  --ion-color-tint: var(--ion-color-dashboard-accent-one-tint);
}

.ion-color-dashboard-accent-two {
  --ion-color-base: var(--ion-color-dashboard-accent-two);
  --ion-color-base-rgb: var(--ion-color-dashboard-accent-two-rgb);
  --ion-color-contrast: var(--ion-color-dashboard-accent-two-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dashboard-accent-two-contrast-rgb);
  --ion-color-shade: var(--ion-color-dashboard-accent-two-shade);
  --ion-color-tint: var(--ion-color-dashboard-accent-two-tint);
}

.ion-color-dashboard-accent-three {
  --ion-color-base: var(--ion-color-dashboard-accent-three);
  --ion-color-base-rgb: var(--ion-color-dashboard-accent-three-rgb);
  --ion-color-contrast: var(--ion-color-dashboard-accent-three-contrast);
  --ion-color-contrast-rgb: var(
    --ion-color-dashboard-accent-three-contrast-rgb
  );
  --ion-color-shade: var(--ion-color-dashboard-accent-three-shade);
  --ion-color-tint: var(--ion-color-dashboard-accent-three-tint);
}

.ion-color-dashboard-accent-four {
  --ion-color-base: var(--ion-color-dashboard-accent-four);
  --ion-color-base-rgb: var(--ion-color-dashboard-accent-four-rgb);
  --ion-color-contrast: var(--ion-color-dashboard-accent-four-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dashboard-accent-four-contrast-rgb);
  --ion-color-shade: var(--ion-color-dashboard-accent-four-shade);
  --ion-color-tint: var(--ion-color-dashboard-accent-four-tint);
}

.ion-color-klein-blue {
  --ion-color-base: var(--ion-color-klein-blue);
  --ion-color-base-rgb: var(--ion-color-klein-blue-rgb);
  --ion-color-contrast: var(--ion-color-klein-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-klein-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-klein-blue-shade);
  --ion-color-tint: var(--ion-color-klein-blue-tint);
}

.ion-color-light {
  --ion-color-base: var(--ion-color-light);
  --ion-color-base-rgb: var(--ion-color-light-rgb);
  --ion-color-contrast: var(--ion-color-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-shade);
  --ion-color-tint: var(--ion-color-light-tint);
}

.ion-color-bg-shadow {
  --ion-color-base: var(--ion-color-bg-shadow);
  --ion-color-base-rgb: var(--ion-color-bg-shadow-rgb);
  --ion-color-contrast: var(--ion-color-bg-shadow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bg-shadow-contrast-rgb);
  --ion-color-shade: var(--ion-color-bg-shadow-shade);
  --ion-color-tint: var(--ion-color-bg-shadow-tint);
}

/*
  END COLOR SHORTCUTS
*/
input:autofill,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
seleect:autofill,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  color: black !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}



