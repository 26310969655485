/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ 

Single Color Generator:
https://ionicframework.com/docs/theming/colors

*/

/** Ionic CSS Variables **/
:root {
  /*
    CONSTANT COLORS
    these don't change regardless of dark or light theme
  */
  --ion-color-constant-light: #fafafa;
  --ion-color-constant-light-rgb: 250, 250, 250;
  --ion-color-constant-light-contrast: #000000;
  --ion-color-constant-light-contrast-rgb: 0, 0, 0;
  --ion-color-constant-light-shade: #dcdcdc;
  --ion-color-constant-light-tint: #fbfbfb;

  --ion-color-constant-dark: #111111;
  --ion-color-constant-dark-rgb: 17, 17, 17;
  --ion-color-constant-dark-contrast: #ffffff;
  --ion-color-constant-dark-contrast-rgb: 255, 255, 255;
  --ion-color-constant-dark-shade: #0f0f0f;
  --ion-color-constant-dark-tint: #292929;

  --ion-color-basic-grey: #626262;
  --ion-color-light-grey: #cacaca;

  /** Design System **/
  --ion-color-text: #4c4c4c;
  --ion-color-text-rgb: 76, 76, 76;
  --ion-color-text-contrast: #ffffff;
  --ion-color-text-contrast-rgb: 255, 255, 255;
  --ion-color-text-shade: #434343;
  --ion-color-text-tint: #5e5e5e;

  --ion-color-main-text: #262e3b;
  --ion-color-main-text-rgb: 38, 46, 59;
  --ion-color-main-text-contrast: #ffffff;
  --ion-color-main-text-contrast-rgb: 255, 255, 255;
  --ion-color-main-text-shade: #212834;
  --ion-color-main-text-tint: #3c434f;

  --ion-color-primary-bg: #f3f6fa;
  --ion-color-primary-bg-rgb: 243, 246, 250;
  --ion-color-primary-bg-contrast: #000000;
  --ion-color-primary-bg-contrast-rgb: 0, 0, 0;
  --ion-color-primary-bg-shade: #d6d8dc;
  --ion-color-primary-bg-tint: #f4f7fb;

  --ion-color-secondary-bg: #eef8b8;
  --ion-color-secondary-bg-rgb: 238, 248, 184;
  --ion-color-secondary-bg-contrast: #000000;
  --ion-color-secondary-bg-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-bg-shade: #d1daa2;
  --ion-color-secondary-bg-tint: #f0f9bf;

  --ion-color-accent-one: #eef8b8;
  --ion-color-accent-one-rgb: 238, 248, 184;
  --ion-color-accent-one-contrast: #000000;
  --ion-color-accent-one-contrast-rgb: 0, 0, 0;
  --ion-color-accent-one-shade: #d1daa2;
  --ion-color-accent-one-tint: #f0f9bf;

  --ion-color-accent-two: #caed6b;
  --ion-color-accent-two-rgb: 202, 237, 107;
  --ion-color-accent-two-contrast: #000000;
  --ion-color-accent-two-contrast-rgb: 0, 0, 0;
  --ion-color-accent-two-shade: #b2d15e;
  --ion-color-accent-two-tint: #cfef7a;
  --ion-color-accent-two-light: #d8eca6;

  --ion-color-accent-three: #fa4616;
  --ion-color-accent-three-rgb: 250, 70, 22;
  --ion-color-accent-three-contrast: #ffffff;
  --ion-color-accent-three-contrast-rgb: 255, 255, 255;
  --ion-color-accent-three-shade: #dc3e13;
  --ion-color-accent-three-tint: #fb592d;
  --ion-color-accent-three-event: #f36c47;

  --ion-color-dashboard-accent-one: #f2a373;
  --ion-color-dashboard-accent-one-rgb: 242, 163, 115;
  --ion-color-dashboard-accent-one-contrast: #000000;
  --ion-color-dashboard-accent-one-contrast-rgb: 0, 0, 0;
  --ion-color-dashboard-accent-one-shade: #d58f65;
  --ion-color-dashboard-accent-one-tint: #f3ac81;

  --ion-color-dashboard-accent-two: #92b1f9;
  --ion-color-dashboard-accent-two-rgb: 146, 177, 249;
  --ion-color-dashboard-accent-two-contrast: #000000;
  --ion-color-dashboard-accent-two-contrast-rgb: 0, 0, 0;
  --ion-color-dashboard-accent-two-shade: #809cdb;
  --ion-color-dashboard-accent-two-tint: #9db9fa;

  --ion-color-dashboard-accent-three: #ef84a2;
  --ion-color-dashboard-accent-three-rgb: 239, 132, 162;
  --ion-color-dashboard-accent-three-contrast: #000000;
  --ion-color-dashboard-accent-three-contrast-rgb: 0, 0, 0;
  --ion-color-dashboard-accent-three-shade: #d2748f;
  --ion-color-dashboard-accent-three-tint: #f190ab;

  --ion-color-dashboard-accent-four: #ab9cf8;
  --ion-color-dashboard-accent-four-rgb: 171, 156, 248;
  --ion-color-dashboard-accent-four-contrast: #000000;
  --ion-color-dashboard-accent-four-contrast-rgb: 0, 0, 0;
  --ion-color-dashboard-accent-four-shade: #9689da;
  --ion-color-dashboard-accent-four-tint: #b3a6f9;

  --ion-color-main: #262e3b;
  --ion-color-main-rgb: 38, 46, 59;
  --ion-color-main-contrast: #ffffff;
  --ion-color-main-contrast-rgb: 255, 255, 255;
  --ion-color-main-shade: #212834;
  --ion-color-main-tint: #3c434f;

  --ion-color-klein-blue: #072cad;
  --ion-color-klein-blue-rgb: 7, 44, 173;
  --ion-color-klein-blue-contrast: #ffffff;
  --ion-color-klein-blue-contrast-rgb: 255, 255, 255;
  --ion-color-klein-blue-shade: #062798;
  --ion-color-klein-blue-tint: #2041b5;

  --ion-error-color: #ff4769;
  --ion-success-color: #4fab52;

  --ion-grid-column-padding: 0;

  --ion-font-family: "Open Sans";
  --heading-font: "Montserrat";

  /** Standard Non Color Styles **/
  --radius: 8px;
  --horizontal-gutter: 8px;
  --toolbar-icon-right-margin: 12px;

  /** Ionic Default Varialbes, edit these with values from above */
  /** primary **/
  --ion-color-primary: #262e3b;
  --ion-color-primary-rgb: 38, 46, 59;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #212834;
  --ion-color-primary-tint: #3c434f;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #d7d8da;
  --ion-color-light-rgb: 215, 216, 218;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #bdbec0;
  --ion-color-light-tint: #dbdcde;

  --ion-background-color: #f6f6f6;
  --ion-background-color-shade: #f6f6f6d3;
  --ion-background-color-tint: #ffffff;

  --ion-color-bg-shadow: #dcdcdc;
  --ion-color-bg-shadow-rgb: 220, 220, 220;
  --ion-color-bg-shadow-contrast: #000000;
  --ion-color-bg-shadow-contrast-rgb: 0, 0, 0;
  --ion-color-bg-shadow-shade: #c2c2c2;
  --ion-color-bg-shadow-tint: #e0e0e0;
}

/*
   * Dark Colors
   * -------------------------------------------
   */

body.dark {
  --ion-color-primary: var(--ion-color-accent-one);
  --ion-color-primary-rgb: var(--ion-color-accent-one-rgb);
  --ion-color-primary-contrast: var(--ion-color-accent-one-contrast);
  --ion-color-primary-contrast-rgb: var(--ion-color-accent-one-contrast-rgb);
  --ion-color-primary-shade: var(--ion-color-accent-one-shade);
  --ion-color-primary-tint: var(--ion-color-accent-one-tint);

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-color-bg-shadow: #1f1f1f;
  --ion-color-bg-shadow-rgb: 31, 31, 31;
  --ion-color-bg-shadow-contrast: #ffffff;
  --ion-color-bg-shadow-contrast-rgb: 255, 255, 255;
  --ion-color-bg-shadow-shade: #1b1b1b;
  --ion-color-bg-shadow-tint: #353535;

  /** Design System **/
  --ion-color-text: #f3f6fa;
  --ion-color-text-rgb: 243, 246, 250;
  --ion-color-text-contrast: #000000;
  --ion-color-text-contrast-rgb: 0, 0, 0;
  --ion-color-text-shade: #d6d8dc;
  --ion-color-text-tint: #f4f7fb;

  --ion-color-main-text: #fafafa;
  --ion-color-main-text-rgb: 250, 250, 250;
  --ion-color-main-text-contrast: #000000;
  --ion-color-main-text-contrast-rgb: 0, 0, 0;
  --ion-color-main-text-shade: #dcdcdc;
  --ion-color-main-text-tint: #fbfbfb;

  --ion-color-primary-bg: #333333;
  --ion-color-primary-bg-rgb: 51, 51, 51;
  --ion-color-primary-bg-contrast: #ffffff;
  --ion-color-primary-bg-contrast-rgb: 255, 255, 255;
  --ion-color-primary-bg-shade: #2d2d2d;
  --ion-color-primary-bg-tint: #474747;

  --ion-color-main: #262e3b;
  --ion-color-main-rgb: 38, 46, 59;
  --ion-color-main-contrast: #ffffff;
  --ion-color-main-contrast-rgb: 255, 255, 255;
  --ion-color-main-shade: #212834;
  --ion-color-main-tint: #3c434f;
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body.dark {
  --ion-background-color: var(--ion-color-primary-bg);
  --ion-background-color-rgb: var(--ion-color-primary-bg-rgb);
  --ion-background-color-shade: var(--ion-color-primary-bg-shade);
  --ion-background-color-tint: var(--ion-color-primary-bg-tint);

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: var(--ion-color-primary-bg);
  --ion-card-background: #1c1c1d;
}

.ios body.dark ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body.dark {
  --ion-background-color: var(--ion-color-primary-bg);
  --ion-background-color-rgb: var(--ion-color-primary-bg-rgb);
  --ion-background-color-shade: var(--ion-color-primary-bg-shade);
  --ion-background-color-tint: var(--ion-color-primary-bg-tint);

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: var(--ion-color-primary-bg);

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf");
}



.gbtn {
  color: #000;
}

:root { --ion-color-green: #00ff00;}

.ion-color-green { --ion-color-base: var(--ion-color-green);}

.whtext{
  color: #fff;
}

:root { --ion-color-dark-black: #00ff00;}

.ion-color-dark-black { --ion-color-base: var(--ion-color-dark-black);}
/* .testbtn{
  opacity: 1;
} */


.alertHeader {
  color: #000 !important;
  text-align: justify;
 
}
h2.alertHeader {
 color: #000 !important;

}


.alert-sub-title.sc-ion-alert-md, .alert-title.sc-ion-alert-md {
 color: #000;
}

.alert-button-group{
	justify-content: space-between !important;
}
.alert-button-role-Cancel {
  width: 48%;
  background-color: #eb445a !important;
  color: #fff !important;
  margin-right: 0px !important;
}
.alert-button-role-Confirm{
background-color: #262e3b !important;
    width: 48% !important;
    color: #fff !important;
    text-align: center !important;
}
.alert-button-inner{
  justify-content: center !important;
}



.gdiv ion-item:nth-child(2){
  background-color: #00ff00;
  color: #000;
  padding: 0 5px;
}
.gdiv ion-item:nth-child(2) .input-wrapper{
  background-color: #00ff00;
}


.winner {
  --ion-background-color:#00ff00;
}

.loser {
  --ion-background-color:#eb445a;
}

.sport {
  --ion-background-color:#d5ec99;
  padding: 10px 0;
  color: #000;
  opacity: 1!important;
}
.sport label {
  opacity: 1!important;
}
/* ion-content::webkit-scrollbar {
  display:block !important;
 } */
/* body{
  --overflow: hidden;
} */

.email {
   font-size: 100px;
}

.emaillab{
  font-size: 26px;
  margin-bottom: 10px;
  font-weight: 500;
}

.locationlab{
  font-size: 25px;
  margin-bottom: 10px;
}

.reportpop .modal-wrapper{
  width: 100%;
  height: 100%;
  padding: 15px;
}
.reportpop .sc-fLcnxK{
  padding-left: 15px;
}