.wave-animate .wave-container.main {
	animation: swell 0.4s ease-out 0s 1, bounce 2s ease-in-out 0.4s 1;
}

.wave-animate .wave-container.accent-two {
	animation: swell 0.7s ease-out 0s 1, bounce 2s ease-in-out 0.7s 1;
}

.wave-animate .wave-container.secondary-bg {
	animation: swell 1s ease-out 0s 1, bounce 2s ease-in-out 1s 1;
}

.wave-animate .sun {
	animation: rise 1s ease-out 0s 1;
}

@keyframes rise {
	0% {
		top: 100%;
	}

	40% {
		top: 100%;
	}

	100% {
		top: 0;
	}
}

@keyframes swell {
	0% {
		transform: translate3d(0,200px,0);
	}
	100% {
		transform: translate3d(0,0,0);
	}
}