body {
    margin: 0;
    padding: 0;
  }
  
  .App {
    font-family: sans-serif;
    height: 100vh;
    display: flex;
    align-items: center;
  }
  
  .StripeElement {
    margin: 15px auto;
    padding: 10px 12px;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  .hydrated {
    visibility: inherit;
    padding-left: 0;
    /* font-weight: 600; */
}
  
  .product {
    width: 100%;
    max-width: 450px;
    margin: auto;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    overflow: hidden;
    margin: 50px auto;
    padding-top: 25px;
}

.product label{
  padding:0px 0px 0px 15px;
  /* font-weight: 600; */
}
  
.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: #252e3b;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}
  
  .product-info {
    padding: 0 16px;
  }
  
  h3.product-title {
    font-size: 28px;
    margin-bottom: 15px;
  }
  
  h4.product-price {
    font-size: 24px;
    margin: 0;
    margin-bottom: 30px;
    color: #777;
    font-weight: 500;
  }